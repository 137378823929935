/* mark active buttons */
    div.activatable button:hover {
        color: #7386D5;
    }
    div.activatable button.active,
    button[aria-expanded="true"] {
        color: var(--bs-font-color);
        background: var(--bs-secondary);
    }


/* Sidebar using bootstrap. Copied from djangosidebar bootstrap app  */

#sidebar a,
#sidebar a:hover,
#sidebar a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#navbar-top {
    padding: 15px 10px;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    background: #fff;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}
.btn-sidebar {
    background: var(--bs-primary);
    color: #fff;
    font-size: 1.1em;
    border: none;
    display: block;
    width: 100%;
    text-align: left;
    border-radius: 0;
}

#sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    background: var(--bs-primary);
    color: #fff;
    transition: all 0.3s;
    overflow-y: auto;
}

#sidebar.active {
    margin-left: -250px;
}


#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover, .btn-sidebar:hover {
    color: #7386D5;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: var(--bs-secondary);
}

#sidebar .dropdown-toggle::after {
    display: block;
    position: absolute;
    right: 20px;
    transform: translateY(-1em);
}

#sidebar ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: var(--bs-primary);
}

#sidebar a.sidebar-btn {
    text-align: center;
    margin: 20px;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: calc(100% - 250px);
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

#content.active {
    width: 100%;
}

/* ---------------------------------------------------
    MEDIAQUERIES

    Could improve and make more bootstrap-like
    
----------------------------------------------------- */

@media (max-width: 1199px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #content {
        width: 100%;
        padding: 5px;
    }
    #content.active {
        width: calc(100% - 250px);
    }
    #sidebarCollapse span {
        display: none;
    }
}

/* Icons */

.mdi-svg {
    display: inline-block;
    font-size: inherit;
    width: 1.5em;
    overflow: visible;
    vertical-align: -.3em;
}

.link-icon::after {
    content: url('data:image/svg+xml; utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16"> <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/> <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/> </svg>');
    margin-left: 0.3em;

}

.download-icon:after
{
    content: url('data:image/svg+xml; utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/></svg>');
    margin-left: 0.3em;
}



